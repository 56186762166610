(function(ns) {
    var SDK_VERSION     = 'v16.0',
        LANGUAGE_CODE   = 'en_US'
    ;

    /**
     * @namespace
     * @alias sFacebook.Service.sFacebookClient
     * @constructor
     *
     * @param $rootScope
     * @param $window
     */
    var sFacebookClient = function sFacebookClient($rootScope, $window) {
        var loading = null,
            _debug  = false
        ;

        var loadSDK = function loadSDK() {
            loading = $.Deferred();

            (function (d, s, id) {
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) {
                    return; // this prevents from loading it twice atm
                }
                js = d.createElement(s);
                js.id = id;
                // https://developers.facebook.com/docs/javascript/quickstart/
                js.defer = true;
                js.async = true;
                js.crossorigin = 'anonymous';

                js.src = "//connect.facebook.net/" + LANGUAGE_CODE + (_debug ? "/sdk/debug.js" : "/sdk.js");

                fjs.parentNode.insertBefore(js, fjs);

                js.addEventListener('load', function (e) {
                    // Resolves the route resolveFacebookSDK resolver
                    loading.resolve();
                });
            }(document, 'script', 'facebook-jssdk'));

            return loading.promise();
        };

        $window.fbAsyncInit = function fbAsyncInit() {
            $rootScope.$apply(function () {
                loading.resolve();
            });
        };

        /**
         * @function
         * @name sFacebook.Service.sFacebookClient#whenSDKReady
         * @returns {$.Deferred}
         */
        this.whenSDKReady = function whenSDKReady() {
            if (!loading) {
                return loadSDK();
            }

            if (loading && loading.state() === 'pending') {
                return loading.promise();
            }

            return $.Deferred().resolve().promise()
        };

        /**
         * @function
         * @name sFacebook.Service.sFacebookClient#createConnection
         * @param {string|number} appId
         * @returns {Model.Facebook.Connection}
         */
        this.createConnection = function createConnection(appId) {
            return new Model.Facebook.Connection(appId, SDK_VERSION);
        };
    };

    ns.sFacebookClient = sFacebookClient;
})(Object.namespace('sFacebook.Service'));

